<template>
  <div
    v-if="!PhoneService.isInPhoneApp()"
    class="header--container"
  >
    <div
      v-if="route && back"
      class="back-button"
    >
      <router-link-component
        :to="route"
      >
        <svg-icon
          v-if="back"
          class="back-icon"
          src="/icons/thin_arrow_left.svg"
        />
        <p class="center-text">
          {{ text }}
        </p>
      </router-link-component>
    </div>
    <div
      v-else-if="!route && back"
      class="back-button"
    >
      <router-link-component
        :to="-1"
      >
        <svg-icon
          v-if="back"
          class="back-icon"
          src="/icons/thin_arrow_left.svg"
        />
        <p class="center-text">
          {{ text }}
        </p>
      </router-link-component>
    </div>
    <div
      v-else
      class="left"
    />
    <slot />
  </div>
</template>

<script>
import PhoneService from '@/Services/Phone/phone.service';
import PhoneHeader from '@/Services/Phone/phone.header';
import routerLinkComponent from '../router/router.link.component.vue';

export default {
  components: { routerLinkComponent },
  props: {
    back: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'Back',
    },
    route: {
      type: String,
      required: false,
      default: '',
    },
    showResourceInfo: {
      type: Boolean,
      default: false,
    },
    showIndicator: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Title',
    },
  },
  data() {
    return {
      PhoneService,
      PhoneHeaderRef: null,
    };
  },
  mounted() {
    this.PhoneHeaderRef = new PhoneHeader(this.$router, this.$route, this.$props, this.title)
      .create();
  },
  beforeDestroy() {
    this.PhoneHeaderRef.destroy();
  },
};
</script>

<style lang="scss">
// To line it up with the icon in side bar
$item-height: 80px;
.header--container {
  background-color: var(--primary-color);
  display: grid;
  padding: 1rem;
  justify-items: center;
  grid-template-columns: 30% 40% 30%;
  top:0;
  position: sticky;
  z-index: 100;
  height: $item-height;
  overflow: hidden;
  h6,h5 {
    color: white;
    margin-bottom: 0;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: fit-content;
  }

  .header-title {
    align-self: center;
  }

  .booking-information--container {
    justify-self: end;
  }

  .icon {
    fill: white;
    width: 24px;
    height: 24px;
  }
}

.back-button{
  align-self: center;
  justify-self: start;
  overflow: hidden;
  max-width: 100%;
  a {
    display: flex;
    overflow: hidden;
  }
  div {
    display: flex;
    overflow: hidden;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    margin-bottom: 0px;
  }
  .back-icon {
    display: flex;
    svg {
      margin: auto;
      width: .9rem;
      height: .9rem;
      fill: white;
    }
  }
}
</style>

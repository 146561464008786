<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="settings">
    <Header
      :back="true"
      :text="$t('Components.Settings.settings')"
      :title="$t('Components.Settings.license_agreement')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Settings.license_agreement') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card>
        <h5 class="x-big-semi margin margin-medium-large margin-bottom">
          Privacy Policy – ROL Intelligent Office
        </h5>
        <h6 class="normal-semi">
          Introduction
        </h6>
        <p>
          The following policy has been drawn up for ROL Ergo AB, hereinafter the ’ROL Ergo’ and
          governs the use of our ROL Intelligent Office Services (the “Services”) accessed via our
          websites and mobile applications.
        </p>
        <h6 class="normal-semi">
          Roles and responsibilities
        </h6>
        <p>
          ROL Ergo is the supplier of the Services acting as data processor which means that we process
          personal data on behalf of data controllers, as defined in the General Data Protection Regulation
          2016/679 (the ´GDPR`). The data controller for the processing of personal data is the company that
          provides you with the Services or has decided about the use of it, in most cases your employer.
        </p>
        <p> ROL Ergo is responsible for this Privacy Policy </p>
        <h6 class="normal-semi">
          Contact Information
        </h6>
        <p> ROL Ergo AB, org nr 556101‐6402 </p>
        <p> Email: gdpr@rolergo.com   </p>
        <p> Visiting address: Flyplatsvägen 1, 555 93 Jönköping </p>
        <h6 class="normal-semi">
          Processing of personal data
        </h6>
        <p>
          In the Services we use the personal data to provide you with access to those portions of data that will
          help you utilize the Services. In order to administrate your access to the Services and administrate
          your account we do also process information from your active directory. By using the Services you
          may also upload personal information, such as your name, email, height, weight, age, calendar
          bookings and information concerning your desk utilization.
        </p>
        <p>
          The purpose, context and scope of the processing is determined by the controller.
        </p>
        <h6 class="normal-semi">
          Principles for our processing of personal data
        </h6>
        <p>
          ROL Ergo shall act responsibly in our processing of personal data. Matters that in various ways affect
          the processing of personal data are found in all areas of our business concerning the Services. As
          such we encourage the inclusion and awareness of matters regarding personal data in both the
          providing and development of the Services.
        </p>
        <p>
          Acting as processor, ROL Ergo shall process and use the personal data only to the extent strictly
          necessary to perform its obligations or as otherwise provided under the agreement with the
          controller.
        </p>
        <p>
          The data must be processed in a legal, correct and transparent manner in relation to the data
          subject. We shall assist the customers acting as data controllers to be transparent as regards which
          data is processed and to ensure that the persons who in different ways are registered in the Services
          can exercise their rights in an effective manner.
        </p>
        <p>
          In order to ensure and demonstrate that we comply with the legal requirements, we shall gather all
          documentation concerning our data protection work.
        </p>
        <h6 class="normal-semi">
          Storage of personal data
        </h6>
        <p>
          The storage period for personal data within the Services is primarily dependent on the data
          controller. Information that you submit such as weight and length can be deleted by yourself in the
          Service while the storage period for other information such as desk utilization is set by the controller.
        </p>
        <h6 class="normal-semi">
          Sub processors
        </h6>
        <p>
          The personal data within the Services is processed by ROL Ergo. The information may be shared
          within the ROL group and transferred to engaged personal data processors, such as providers of IT
          services. ROL Ergo has entered into data processing agreements in order to regulate the processing
          of personal data in accordance with applicable legislation.
        </p>
        <p>
          ROL Ergo will not subcontract personal data processing without the written permission of the data
          controller. If written permission is provided, then ROL Ergo will ensure that all third parties engaged
          to store or process personal data on our behalf are obliged to comply with the contents of this policy
          and the GDPR. Assurance of such compliance is obtained from all sub processors, whether companies
          or individuals, prior to granting them access to personal data from ROL Ergo.
        </p>
        <h6 class="normal-semi">
          Transfer of personal data
        </h6>
        <p>
          Where possible we avoid the transfer of personal data to a third country, although if this is deemed
          appropriate or necessary it may only take place once sufficient security measures have been taken
          and documented. ROL Ergo will not transfer, access or process any personal data outside the EU
          without the express prior written consent of the data Controller.
        </p>
        <h6 class="normal-semi">
          Breach management
        </h6>
        <p>
          A security breach is an incident that leads to the accidental or unlawful destruction, loss, alteration,
          unauthorised disclosure of, or access to, personal data transmitted, retained or otherwise processed.
        </p>
        <p>
          All security breaches concerning personal data shall be notified to the data controller without undue
          delay and ROL Ergo shall assist the data controller in ensuring compliance with the obligations
          connected to it, taking into account the nature of processing and the information available to the
          processor
        </p>
        <h6 class="normal-semi">
          Confidentiality and access
        </h6>
        <p>
          Contemporary access to the personal data in the Services is only granted to external IT‐security
          consultants in order for ROL Ergo to fulfill IT‐security standards pursuant to ISO‐certification 27001.
          All other access to personal data is only granted to employees and personnel that need access and a
          necessity assessment is performed in each case.
        </p>
        <p>
          ROL Ergo shall take all reasonable steps to ensure the reliability of its employees and personnel who
          have access to the personal data and ensure that all such employees and personnel are bound by a
          confidentiality obligation. Our employees shall receive relevant information about and training in the
          processing of personal data.
        </p>
        <h6 class="normal-semi">
          Rights as data subject
        </h6>
        <p>
          As a data subject GDPR give you certain rights. If you want to exercise any of your rights, you shall
          contact the data controller. Upon request from the controller ROL Ergo shall assist the controller in
          performing these rights in accordance with applicable legislation.
        </p>
        <p class="italic">
          Right to object to processing
        </p>
        <p>
          You may have the right to object to the processing of your personal data for marketing purposes or
          when the processing is based on legitimate interests.
        </p>
        <p class="italic">
          Right to access
        </p>
        <p>
          You may have the right to obtain information about what personal data we process about you and
          how the data is processed.
        </p>
        <p class="italic">
          Right to rectification
        </p>
        <p>
          If any of your personal data that we process about you is inaccurate you may have the right to
          require rectification without undue delay.
        </p>
        <p class="italic">
          Right to erasure and restriction
        </p>
        <p>
          You may have the right to require erasure of your personal data that we process. You also have the
          right to require restriction of the processing of your personal data, for example if your personal data
          is inaccurate.
        </p>
        <p class="italic">
          Right to data portability
        </p>
        <p>
          In the case the processing of personal data is based on your consent or on a contract with you and
          the personal data is processed automatically, you may have the right to receive the personal data in
          a structured, commonly used and machine‐readable format and to transfer these to another
          controller or to have our help to transfer the data to another controller when that is technically
          possible.
        </p>
        <h6 class="normal-semi">
          IT Security
        </h6>
        <p>
          ROL Ergo aims to take appropriate technical and organizational measures to prevent and limit risks
          associated with the disclosure of personal data such as unauthorized access, abuse, alteration and
          destruction.
        </p>
        <p>
          We have adopted an IT policy wherein our employees’ use of our IT environment is regulated in
          greater detail.
        </p>
        <p>
          More details about IT security regarding the ROL Intelligent Office application and
          platform can be found in the ROL.iO Security Architecture appendix.
        </p>
        <h6 class="normal-semi">
          Validity
        </h6>
        <p>
          This Privacy Policy remains current until it is reviewed or reissued. When this occurs, the new Privacy
          Policy will be published in the Services.
        </p>
      </Card>
    </div>
  </div>
</template>

<script>
/* Components */
// import ExtendedRouterLink from '@/components/router/router.link.component.vue';
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';

export default {
  components: {
    // ExtendedRouterLink,
    Header,
    Card,
  },

};
</script>

/* eslint-disable key-spacing */
import PhoneService from './phone.service';

const EVENT = PhoneService.events;

class PhoneHeader {
  constructor(router, currentRoute, data, title) {
    this.router = router;
    this.data = data;
    this.title = title;
    this.currentRoute = currentRoute;
  }

  create() {
    const newState = {
      title:                  this.title,
      showBackButton:         this.data.back,
      showResourceOccupancy:  this.data.showIndicator,
      showResourceInfoButton: this.data.showResourceInfo,
      resourceOccupancy:      this.data.showIndicator ? 'OCCUPIED' : undefined,
    };
    PhoneService.send(EVENT.phone_navigationbar_state, newState);
    this.addListeners();
    return this;
  }

  addListeners() {
    PhoneService.broadcast.$once(EVENT.phone_navigationbar_btnback, this.backHandler);
    PhoneService.broadcast.$once(EVENT.phone_navigationbar_btninfo, this.infoHandler);
  }

  backHandler = () => {
    if (!this.data.route) {
      this.router.go(-1);
      return;
    }
    this.router.push(this.data.route);
  }

  infoHandler = () => {
    this.router.push(`${window.location.pathname}/information`);
  }

  destroy() {
    PhoneService.broadcast.$off(EVENT.phone_navigationbar_btnback, this.backHandler);
    PhoneService.broadcast.$off(EVENT.phone_navigationbar_btninfo, this.infoHandler);
  }
}

export default PhoneHeader;

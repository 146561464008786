<template>
  <div
    class="padding margin margin-card margin-left margin-right"
    :class="marginSides"
  >
    <div
      class="card"
      :class="position"
    >
      <p
        v-if="header"
        class="card_header"
      >
        {{ header }}
      </p>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  /*
  Valid inputs: bottom, tab,
  This can be expanded as needed
  */
  props: {
    header: {
      type: String,
      required: false,
      default: null,
    },
    position: {
      type: String,
      required: false,
      default: '',
    },
    marginSides: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../scss/breakpoints.scss';

.card {
  padding: 1.5rem 0rem;
  background: #fff;
  background: var(--card-background-color);
  border: none;
  border-bottom: solid 2px #999;
  border-bottom: solid 2px var(--border-color);
  .card_header {
    margin-bottom: 1rem;
    color: var(--secondary-font-color);
    font-size: 0.875rem;
    font-weight: 500;
  }
  @include phone {
    padding: 2rem 1rem;
  }
  &.no-padding {
    padding: 0px;
    margin: 0px;
  }
  &.no-padding-sides {
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
  }
  border-radius: 0;
}

.tab {
  padding: 2rem 0rem;
  background: #fff;
  background: var(--card-background-color);
  border: none;
  &.no-padding {
    padding: 0px;
    margin: 0px;
  }
  border-radius: 0;
}

.fill{
  padding: 2rem 0rem;
}

.no-margin-sides {
  margin-right: 0px;
  margin-left: 0px;
}

.bottom {
  // width: 100%;
  &.medium-small-padding-top {
    padding-top: 1.5rem;
  }

  &.normal-padding{
    padding-top: 2rem;
  }
  // padding-top: 3rem;
  // background: #fff;
  // background: var(--card-background-color);
  border: none;

  &.no-padding-top {
    padding-top: unset;
  }

  &.no-padding-sides {
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
  }
  // border-radius: 0;
}

</style>

<template>
  <!--
    If to is not number use normal router-link
    else use router 'to' as a number to go the specifed amount of steps
    forward or backward (-1 will be the most used)
  -->

  <router-link
    v-if="!(typeof to === 'number')"
    :to="to"
  >
    <slot />
  </router-link>

  <div
    v-else
    @click="$router.go(to)"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ExtendedRouterLink',
  props: {
    // The target route of the link. It can be either a string, a location descriptor object
    // or a number specifying the amounts of steps to navigate.
    to: {
      type: [Object, String, Number],
      required: true,
    },
  },
  data() {
    return {
      toObject: null,
    };
  },
  computed: {
  },
  mounted() {
    this.toObject = this.to;
  },
};
</script>
<style lang="scss" scoped>
*{
  &:hover{
    cursor: pointer;
  }
}
</style>
